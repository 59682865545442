[
    {
        "id":1,
        "image":"assets/images/items/1.jpg",
        "user":"assets/images/avatar/1.jpg",
        "name":"@StreetBoy",
        "title":"Genuine Undead #3902",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"13th Sept 2025",
        "showDate":"false",
        "collection":"Digital Collection",
        "category":"games"
    },
    {
        "id":2,
        "image":"assets/images/items/2.gif",
        "user":"assets/images/avatar/2.jpg",
        "name":"@CutieGirl",
        "title":"Windchime #768",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"29th Nov 2025",
        "showDate":"true",
        "collection":"GIF Collection",
        "category":"art"
    },
    {
        "id":3,
        "image":"assets/images/items/2.jpg",
        "user":"assets/images/items/3.gif",
        "name":"@ButterFly",
        "title":"Probably A Label #3277",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"13th Sept 2025",
        "showDate":"false",
        "collection":"Sports Collection",
        "category":"music"
    },
    {
        "id":4,
        "image":"assets/images/items/3.jpg",
        "user":"assets/images/avatar/3.jpg",
        "name":"@NorseQueen",
        "title":"Probably A Label #1711",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"13th March 2025",
        "showDate":"false",
        "collection":"Photography Collection",
        "category":"video"
    },
    {
        "id":5,
        "image":"assets/images/items/3.gif",
        "user":"assets/images/avatar/4.jpg",
        "name":"@BigBull",
        "title":"Shibuya Scramble Punks",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"5th May 2025",
        "showDate":"false",
        "collection":"Illustrations Collection",
        "category":"memes"
    },
    {
        "id":6,
        "image":"assets/images/items/4.jpg",
        "user":"assets/images/avatar/5.jpg",
        "name":"@Angel",
        "title":"Probably A Label #650",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"19th June 2025",
        "showDate":"false",
        "collection":"Animations Collection",
        "category":"games"
    },
    {
        "id":7,
        "image":"assets/images/items/5.jpg",
        "user":"assets/images/avatar/6.jpg",
        "name":"@CrazyAnyone",
        "title":"Looki#0147",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"20th June 2025",
        "showDate":"true",
        "collection":"Virtual Reality Collection",
        "category":"art"
    },
    {
        "id":8,
        "image":"assets/images/items/6.jpg",
        "user":"assets/images/items/5.gif",
        "name":"@Princess",
        "title":"Poob #285",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"31st Aug 2025",
        "showDate":"false",
        "collection":"Cartoon Collection",
        "category":"music"
    },
    {
        "id":9,
        "image":"assets/images/items/8.jpg",
        "user":"assets/images/avatar/7.jpg",
        "name":"@LooserBad",
        "title":"Umber Arrow",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"1st Sept 2025",
        "showDate":"false",
        "collection":"GIF Collection",
        "category":"memes"
    },
    {
        "id":10,
        "image":"assets/images/items/4.gif",
        "user":"assets/images/items/5.gif",
        "name":"@Princess",
        "title":"Gloam Druid",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"13th Sept 2025",
        "showDate":"true",
        "collection":"Photography Collection",
        "category":"art"
    },
    {
        "id":11,
        "image":"assets/images/items/5.gif",
        "user":"assets/images/avatar/8.jpg",
        "name":"@PandaOne",
        "title":"Azuki #7421",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"19th June 2025",
        "showDate":"false",
        "collection":"Cartoon Collection",
        "category":"games"
    },
    {
        "id":12,
        "image":"assets/images/items/9.jpg",
        "user":"assets/images/items/2.gif",
        "name":"@FunnyGuy",
        "title":"Wolf-Cult Vanguard",
        "value":"3.5 ETH",
        "bid":"3.55 ETH",
        "date":"13th Sept 2025",
        "showDate":"false",
        "collection":"Digital Collection",
        "category":"music"
    }
]